<template>
  <a-card :bordered="false" class="card-area">
    <div class="operator">
      <a-button type="primary" @click="handleAdd()">新增</a-button>
      <a-popconfirm
        title="确定删除选中的文件？"
        okText="确定"
        cancelText="取消"
        @confirm="handleBatchDelete()"
      >
        <a-button>删除</a-button>
      </a-popconfirm>
    </div>
    <div>
      <!-- 表格区域 -->
      <a-table
        :data-source="fileData"
        :columns="columns"
        :rowKey="(record) => record.id"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <template slot="fileUrl" slot-scope="text, record">
          <a :href="text" slot="extra" target="_blank">
            <a-icon 
              :type="getIconType(record)"
              theme="twoTone"
              twoToneColor="#4a9ff5"
              :title="text"
            ></a-icon>
          </a>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-icon
            type="edit"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="handleEdit(record)"
          ></a-icon>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除该文件？"
            okText="确定"
            cancelText="取消"
            @confirm="handleDelete(record)"
          >
            <a-icon
              type="delete"
              theme="twoTone"
              twoToneColor="#4a9ff5"
            ></a-icon>
          </a-popconfirm>
        </template>
      </a-table>
      <file-detail ref="fileDetail" @success="handleSuccess"></file-detail>
    </div>
  </a-card>
</template>
<script>
import FileDetail from "./FileDetail.vue";
export default {
  components: { FileDetail },
  data() {
    return {
      columns: [
        {
          title: "文件名",
          width: 200,
          dataIndex: "fileName",
        },
        {
          title: "文件Url",
          width: 200,
          dataIndex: "fileUrl",
          scopedSlots: { customRender: "fileUrl" },
        },
        {
          title: "创建时间",
          width: 200,
          dataIndex: "createTime",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 100,
        },
      ],
      pagination: {
        pageSizeOptions: ["1", "10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: 0,
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
        onChange: (current, pageSize) => {
          this.pagination.current = current;
          this.pagination.pageSize = pageSize;
        },
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
        },
      },
      loading: false,
      fileData: [],
      queryParams: {},
      selectedRowKeys: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.queryParams.pageSize = this.pagination.pageSize;
      this.queryParams.pageNum = this.pagination.current;
      this.$get("training/file/page", {
        ...this.queryParams,
      }).then((r) => {
        let data = r.data.data;
        this.loading = false;
        this.fileData = data.rows;
        this.pagination.total = data.total;
      });
    },
    reset() {
      this.queryParams = {};
      this.pagination.current = 1;
      this.fetch();
    },
    handleAdd() {
      this.$refs.fileDetail.setForm(0);
    },
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleEdit(record) {
      this.$refs.fileDetail.setForm(1, record);
    },
    handleSuccess() {
      this.reset();
    },
    handleTableChange(pagination, filters, sorter) {
      this.fetch();
    },
    handleDelete(record) {
      this.loading = true;
      this.$delete(`training/file/${record.id}`)
        .then((r) => {
          this.$message.success(r.data.message);
          this.fetch();
        })
        .finally(() => {
        });
    },
    getIconType(record){
      if (record.fileType.indexOf('ms-powerpoint') != -1 || record.fileType.indexOf('presentationml.presentation') != -1) {
        return 'file-ppt'
      }
      if (record.fileType.indexOf('msword') != -1 || record.fileType.indexOf('wordprocessingml.document') != -1) {
        return 'file-word'
      }
      if (record.fileType.indexOf('ms-excel') != -1 || record.fileType.indexOf('spreadsheetml.sheet') != -1) {
        return 'file-excel'
      }
      if (record.fileType.indexOf('pdf') != -1) {
        return 'file-pdf'
      }
      if (record.fileType.indexOf('image') != -1) {
        return 'file-image'
      }
      if (record.fileType.indexOf('video') != -1) {
        return 'video-camera'
      }
      return 'file'      
    },
    handleBatchDelete() {
      if (this.selectedRowKeys.length > 0) {
        this.loading = true;
        let ids = this.selectedRowKeys.join(",");
        this.$delete("training/file/" + ids)
          .then((r) => {
            this.$message.success(r.data.message);
            this.fetch();
          })
          .finally(() => {
            this.selectedRowKeys = [];
          });
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../utils/Common.less";
</style>